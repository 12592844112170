<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                国投证券（原“安信证券”）携手共议数智交易行业的赋能发展
              </p>
              <div class="fst-italic mb-2">2023年10月9日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国投证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/58/01-活动.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    9月22日，国投证券上海浦西分公司携手非凸科技、通联数据在上海共同开展“私募闭门交流会”，与资方管理人就如何赋能私募可持续发展，给出了精彩纷呈的见解与讨论。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/58/02-廖威.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    国投证券以“打造数字化券商”为目标，加强科技创新与业务融合共创，持续推进金融高科技创新探索与实践，助力行业高质量发展。会上，国投证券私募机构部廖威从私募管理人的交易需求出发，提出了全方位解决方案。为促进对私募基金的发展与支持，国投证券提供了从行情、柜台、报盘、网络到策略的全链路服务体系，并引入了包括非凸在内的市场主流算法，旨在为客户提供极致交易链路、专业交易工具、算法超市等一体化解决方案。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/58/03-周升.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技机构业务经理周升分享了算法服务如何赋能数智交易。随着机构化的日益深入、数智交易的快速发展，非凸科技基于券商和私募的需求，打造了一站式智能交易领域服务解决方案。非凸科技已建立清晰的算法服务规划，未来将进一步推进算法服务向“广度”、“深度”方向拓展，不断提升算法赋能机构客户的效力。与此同时，Rust将持续作为高性能业务的技术底座，为机构客户提供极致交易的同时保障了交易的安全性和稳定性。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/58/04-高凌峻.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    通联数据致力于打造打通金融信息服务产业链上下游的数据与分析平台，为资产管理和财富管理提供涵盖数据生产、信息萃取、智能建模、投资辅助决策等一站式的数智化产品和服务。通联数据资深算法工程师高凌峻先后围绕“A股市场风险模型的优化”、“NLP模型下的主题大数据”等话题进行了分享。通联数据基于先进的研究方法，搭建A股市场风险模型，以便更好地解释A股股票收益率、预测A股市场风险。除此之外，通联数据基于大语言模型，在找信息、看研报、查数据、写点评、做复盘等多个场景提供AI投研助理服务。
                  </p>
                  <p>
                    未来，非凸科技与国投证券的合作将会持续深入，聚焦技术创新与场景应用，合力解决重点、难点问题，以更优质的产品和服务，助力数智交易行业高质量发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News58",
};
</script>

<style></style>
